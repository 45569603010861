import React from "react"

export default function FlowerHeader() {
  return (
    <svg
      width="79"
      height="24"
      viewBox="0 0 79 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0974 19.6857L10.7112 24L9.34378 19.6857C9.10715 18.9417 9.10715 18.1427 9.34378 17.3988L10.7112 13.0798L12.0786 17.3988C12.3213 18.1408 12.3279 18.9398 12.0974 19.6857V19.6857ZM12.0974 4.31906L10.7112 0L9.34378 4.31906C9.10715 5.06297 9.10715 5.86198 9.34378 6.60589L10.7112 10.9202L12.0974 6.60118C12.3331 5.85872 12.3331 5.06152 12.0974 4.31906V4.31906ZM6.74103 15.8853L9.79644 12.5422L5.37365 13.5136C4.61141 13.68 3.91952 14.0786 3.39329 14.6546L0.337891 18.0024L4.76068 17.0263C5.52292 16.8599 6.2148 16.4613 6.74103 15.8853V15.8853ZM18.0573 9.34538L21.1127 6.00236L16.6994 6.97367C15.9371 7.14008 15.2453 7.53873 14.719 8.11473L11.6542 11.4625L16.077 10.4864C16.8426 10.3219 17.538 9.92306 18.0668 9.34538H18.0573ZM5.37365 10.4864L9.79644 11.4625L6.74103 8.11473C6.2148 7.53873 5.52292 7.14008 4.76068 6.97367L0.337891 6.00236L3.39329 9.34538C3.91952 9.92138 4.61141 10.32 5.37365 10.4864V10.4864ZM16.69 17.0263L21.1127 18.0024L18.0573 14.6546C17.5311 14.0786 16.8392 13.68 16.077 13.5136L11.6542 12.5422L14.7096 15.8853C15.2383 16.4629 15.9338 16.8617 16.6994 17.0263H16.69Z"
        fill="#F8F0D8"
      />
      <path
        d="M40.8723 19.6857L39.4861 24L38.1187 19.6857C37.8821 18.9417 37.8821 18.1427 38.1187 17.3988L39.4861 13.0798L40.8535 17.3988C41.0962 18.1408 41.1028 18.9398 40.8723 19.6857V19.6857ZM40.8723 4.31906L39.4861 0L38.1187 4.31906C37.8821 5.06297 37.8821 5.86198 38.1187 6.60589L39.4861 10.9202L40.8723 6.60118C41.108 5.85872 41.108 5.06152 40.8723 4.31906V4.31906ZM35.5159 15.8853L38.5713 12.5422L34.1485 13.5136C33.3863 13.68 32.6944 14.0786 32.1682 14.6546L29.1128 18.0024L33.5356 17.0263C34.2978 16.8599 34.9897 16.4613 35.5159 15.8853V15.8853ZM46.8322 9.34538L49.8876 6.00236L45.4743 6.97367C44.712 7.14008 44.0202 7.53873 43.4939 8.11473L40.4291 11.4625L44.8519 10.4864C45.6175 10.3219 46.3129 9.92306 46.8417 9.34538H46.8322ZM34.1485 10.4864L38.5713 11.4625L35.5159 8.11473C34.9897 7.53873 34.2978 7.14008 33.5356 6.97367L29.1128 6.00236L32.1682 9.34538C32.6944 9.92138 33.3863 10.32 34.1485 10.4864V10.4864ZM45.4649 17.0263L49.8876 18.0024L46.8322 14.6546C46.306 14.0786 45.6141 13.68 44.8519 13.5136L40.4291 12.5422L43.4845 15.8853C44.0132 16.4629 44.7087 16.8617 45.4743 17.0263H45.4649Z"
        fill="#F8F0D8"
      />
      <path
        d="M69.6472 19.6857L68.261 24L66.8936 19.6857C66.657 18.9417 66.657 18.1427 66.8936 17.3988L68.261 13.0798L69.6284 17.3988C69.8711 18.1408 69.8777 18.9398 69.6472 19.6857V19.6857ZM69.6472 4.31906L68.261 0L66.8936 4.31906C66.657 5.06297 66.657 5.86198 66.8936 6.60589L68.261 10.9202L69.6472 6.60118C69.8829 5.85872 69.8829 5.06152 69.6472 4.31906V4.31906ZM64.2908 15.8853L67.3462 12.5422L62.9235 13.5136C62.1612 13.68 61.4693 14.0786 60.9431 14.6546L57.8877 18.0024L62.3105 17.0263C63.0727 16.8599 63.7646 16.4613 64.2908 15.8853V15.8853ZM75.6071 9.34538L78.6625 6.00236L74.2492 6.97367C73.487 7.14008 72.7951 7.53873 72.2688 8.11473L69.204 11.4625L73.6268 10.4864C74.3924 10.3219 75.0878 9.92306 75.6166 9.34538H75.6071ZM62.9235 10.4864L67.3462 11.4625L64.2908 8.11473C63.7646 7.53873 63.0727 7.14008 62.3105 6.97367L57.8877 6.00236L60.9431 9.34538C61.4693 9.92138 62.1612 10.32 62.9235 10.4864V10.4864ZM74.2398 17.0263L78.6625 18.0024L75.6071 14.6546C75.0809 14.0786 74.389 13.68 73.6268 13.5136L69.204 12.5422L72.2594 15.8853C72.7881 16.4629 73.4836 16.8617 74.2492 17.0263H74.2398Z"
        fill="#F8F0D8"
      />
    </svg>
  )
}
