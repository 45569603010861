import React from "react"

export default function LeafBranch({}) {
  return (
    <svg
      width="67"
      height="32"
      viewBox="0 0 67 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_199_520)">
        <g opacity="0.5">
          <path
            d="M62.0955 10.0357C62.0955 10.6749 62.6137 11.1931 63.2528 11.1931C63.892 11.1931 64.4102 10.6749 64.4102 10.0357C64.4102 9.39657 63.892 8.87842 63.2528 8.87842C62.6137 8.87842 62.0955 9.39657 62.0955 10.0357Z"
            fill="#F8F0D8"
          />
          <path
            d="M58.7708 10.9151C58.7708 11.5543 59.289 12.0724 59.9281 12.0724C60.5673 12.0724 61.0854 11.5543 61.0854 10.9151C61.0854 10.276 60.5673 9.75781 59.9281 9.75781C59.289 9.75781 58.7708 10.276 58.7708 10.9151Z"
            fill="#F8F0D8"
          />
          <path
            d="M61.0364 12.7633C61.0364 13.4024 61.5546 13.9206 62.1938 13.9206C62.8329 13.9206 63.3511 13.4024 63.3511 12.7633C63.3511 12.1241 62.8329 11.606 62.1938 11.606C61.5546 11.606 61.0364 12.1241 61.0364 12.7633Z"
            fill="#F8F0D8"
          />
          <path
            d="M63.9564 12.9229C63.9564 13.5621 64.4745 14.0803 65.1137 14.0803C65.7528 14.0803 66.271 13.5621 66.271 12.9229C66.271 12.2838 65.7528 11.7656 65.1137 11.7656C64.4745 11.7656 63.9564 12.2838 63.9564 12.9229Z"
            fill="#F8F0D8"
          />
          <path
            d="M61.7806 15.5851C61.7806 16.2242 62.2987 16.7424 62.9379 16.7424C63.5771 16.7424 64.0952 16.2242 64.0952 15.5851C64.0952 14.9459 63.5771 14.4277 62.9379 14.4277C62.2987 14.4277 61.7806 14.9459 61.7806 15.5851Z"
            fill="#F8F0D8"
          />
          <path
            d="M58.5828 14.3458C58.5828 14.985 59.101 15.5031 59.7401 15.5031C60.3793 15.5031 60.8975 14.985 60.8975 14.3458C60.8975 13.7066 60.3793 13.1885 59.7401 13.1885C59.101 13.1885 58.5828 13.7066 58.5828 14.3458Z"
            fill="#F8F0D8"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M62.6639 1.15732C62.6639 1.79648 63.182 2.31463 63.8212 2.31463C64.4604 2.31463 64.9785 1.79648 64.9785 1.15732C64.9785 0.518148 64.4604 0 63.8212 0C63.182 0 62.6639 0.518148 62.6639 1.15732Z"
            fill="#F8F0D8"
          />
          <path
            d="M59.3392 2.03671C59.3392 2.67588 59.8573 3.19403 60.4965 3.19403C61.1357 3.19403 61.6538 2.67588 61.6538 2.03671C61.6538 1.39754 61.1357 0.879395 60.4965 0.879395C59.8573 0.879395 59.3392 1.39754 59.3392 2.03671Z"
            fill="#F8F0D8"
          />
          <path
            d="M61.6048 3.88486C61.6048 4.52402 62.1229 5.04217 62.7621 5.04217C63.4013 5.04217 63.9194 4.52402 63.9194 3.88486C63.9194 3.24569 63.4013 2.72754 62.7621 2.72754C62.1229 2.72754 61.6048 3.24569 61.6048 3.88486Z"
            fill="#F8F0D8"
          />
          <path
            d="M64.5247 4.04452C64.5247 4.68369 65.0429 5.20184 65.682 5.20184C66.3212 5.20184 66.8394 4.68369 66.8394 4.04452C66.8394 3.40536 66.3212 2.88721 65.682 2.88721C65.0429 2.88721 64.5247 3.40536 64.5247 4.04452Z"
            fill="#F8F0D8"
          />
          <path
            d="M62.3489 6.70663C62.3489 7.3458 62.8671 7.86395 63.5063 7.86395C64.1454 7.86395 64.6636 7.3458 64.6636 6.70663C64.6636 6.06746 64.1454 5.54932 63.5063 5.54932C62.8671 5.54932 62.3489 6.06746 62.3489 6.70663Z"
            fill="#F8F0D8"
          />
          <path
            d="M59.1512 5.46737C59.1512 6.10654 59.6693 6.62469 60.3085 6.62469C60.9477 6.62469 61.4658 6.10654 61.4658 5.46737C61.4658 4.82821 60.9477 4.31006 60.3085 4.31006C59.6693 4.31006 59.1512 4.82821 59.1512 5.46737Z"
            fill="#F8F0D8"
          />
        </g>
        <g opacity="0.5">
          <path
            d="M55.0125 5.01767C55.0125 5.65684 55.5307 6.17498 56.1698 6.17498C56.809 6.17498 57.3271 5.65684 57.3271 5.01767C57.3271 4.3785 56.809 3.86035 56.1698 3.86035C55.5307 3.86035 55.0125 4.3785 55.0125 5.01767Z"
            fill="#F8F0D8"
          />
          <path
            d="M51.6878 5.89706C51.6878 6.53623 52.206 7.05438 52.8451 7.05438C53.4843 7.05438 54.0024 6.53623 54.0024 5.89706C54.0024 5.25789 53.4843 4.73975 52.8451 4.73975C52.206 4.73975 51.6878 5.25789 51.6878 5.89706Z"
            fill="#F8F0D8"
          />
          <path
            d="M53.9534 7.74521C53.9534 8.38438 54.4716 8.90252 55.1108 8.90252C55.7499 8.90252 56.2681 8.38438 56.2681 7.74521C56.2681 7.10604 55.7499 6.58789 55.1108 6.58789C54.4716 6.58789 53.9534 7.10604 53.9534 7.74521Z"
            fill="#F8F0D8"
          />
          <path
            d="M56.8734 7.90878C56.8734 8.54795 57.3915 9.0661 58.0307 9.0661C58.6698 9.0661 59.188 8.54795 59.188 7.90878C59.188 7.26961 58.6698 6.75146 58.0307 6.75146C57.3915 6.75146 56.8734 7.26961 56.8734 7.90878Z"
            fill="#F8F0D8"
          />
          <path
            d="M54.6976 10.567C54.6976 11.2062 55.2157 11.7243 55.8549 11.7243C56.4941 11.7243 57.0122 11.2062 57.0122 10.567C57.0122 9.92782 56.4941 9.40967 55.8549 9.40967C55.2157 9.40967 54.6976 9.92782 54.6976 10.567Z"
            fill="#F8F0D8"
          />
          <path
            d="M51.4998 9.32773C51.4998 9.96689 52.018 10.485 52.6571 10.485C53.2963 10.485 53.8145 9.96689 53.8145 9.32773C53.8145 8.68856 53.2963 8.17041 52.6571 8.17041C52.018 8.17041 51.4998 8.68856 51.4998 9.32773Z"
            fill="#F8F0D8"
          />
        </g>
        <path
          d="M54.0355 22.4472L60.5786 22.3941C57.3412 20.2601 53.5411 19.1396 49.6639 19.1756L43.1207 19.2206C46.3578 21.3564 50.1575 22.4796 54.0355 22.4472V22.4472Z"
          fill="#F8F0D8"
        />
        <path
          d="M40.0043 27.2684L46.3716 28.7406C43.7236 25.9168 40.296 23.9439 36.5242 23.0726L30.1569 21.6045C32.8044 24.428 36.2324 26.3997 40.0043 27.2684Z"
          fill="#F8F0D8"
        />
        <path
          d="M24.4809 29.0351L30.292 32.0204C28.4218 28.6274 25.5848 25.8672 22.1417 24.091L16.3306 21.1016C18.2005 24.4958 21.0375 27.2573 24.4809 29.0351Z"
          fill="#F8F0D8"
        />
        <path
          d="M44.5559 13.6958L48.1792 8.25684C44.6035 9.73835 41.5488 12.2493 39.4032 15.4706L35.7595 20.9055C39.343 19.4299 42.4054 16.9198 44.5559 13.6958V13.6958Z"
          fill="#F8F0D8"
        />
        <path
          d="M31.7476 16.7138L36.52 12.2686C32.702 12.8845 29.1525 14.6194 26.3209 17.2536L21.5444 21.7152C25.3659 21.0956 28.9171 19.3549 31.7476 16.7138V16.7138Z"
          fill="#F8F0D8"
        />
        <path
          d="M18.9925 14.5993L23.7812 10.1377C19.9617 10.754 16.4116 12.4921 13.5821 15.1309L8.80566 19.5925C12.6203 18.972 16.1653 17.2343 18.9925 14.5993V14.5993Z"
          fill="#F8F0D8"
        />
        <path
          d="M0.160699 16.2513C0.15961 16.1941 0.173725 16.1377 0.201596 16.0878C0.245778 16.0061 0.320434 15.9453 0.409295 15.9185C0.498155 15.8917 0.594017 15.9011 0.675974 15.9446C14.5801 23.4529 39.5953 24.1235 54.7059 13.1638C54.7424 13.1332 54.7847 13.1104 54.8304 13.0969C54.8761 13.0833 54.924 13.0793 54.9713 13.0851C55.0185 13.0908 55.0641 13.1062 55.1052 13.1303C55.1463 13.1544 55.1819 13.1867 55.21 13.2252C55.2381 13.2636 55.258 13.3075 55.2684 13.3539C55.2788 13.4004 55.2796 13.4485 55.2707 13.4953C55.2618 13.5421 55.2433 13.5865 55.2165 13.6259C55.1897 13.6652 55.1551 13.6986 55.1148 13.724C39.7916 24.8392 14.4247 24.1644 0.344723 16.558C0.289134 16.5284 0.242652 16.4842 0.210238 16.4302C0.177824 16.3762 0.160699 16.3143 0.160699 16.2513V16.2513Z"
          fill="#F8F0D8"
        />
      </g>
      <defs>
        <clipPath id="clip0_199_520">
          <rect
            width="32"
            height="66.6786"
            fill="white"
            transform="translate(66.8394) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
