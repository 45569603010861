import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"

import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import typography from "../../styles/typography"

import { Body } from "../typography"

const Hover: any = styled.div`
  width: 100%;
  height: 100%;

  background: ${props => (props.color ? props.color : colors.sapphireDark)};
  color: ${colors.creamLight};

  position: absolute;
  left: 0;
  top: 0;

  margin: 0;

  box-sizing: border-box;
  padding: 32px 40px 50px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  display: none;
  opacity: 0;
  transition: opacity 0.3s linear;

  p {
    z-index: 2;
  }

  &:after {
    display: block;
    position: absolute;
    top: 0;
    right: calc(-100% - 24px);

    content: "";

    transform: translateX(-50%);
    transform: ${(props: any) =>
      props.right ? "translateX(50%)" : "translateX(-50%)"};

    height: 100%;
    width: 50vw;

    background: ${props => (props.color ? props.color : colors.sapphireDark)};

    z-index: 0;
  }
`

const NameWrapper = styled.div``

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.creamLight};

  max-width: 1440px;

  position: relative;

  margin-top: 22px;
  padding-bottom: 22px;

  > div:first-child {
    display: flex;
    width: 205px;
    margin: 0 auto;
    justify-content: center;
  }

  ${NameWrapper} {
    text-align: left;
    margin-left: 25px;
    width: 150px;
  }

  img {
    width: 32px;
    height: 32px;
    overflow: visible;
    object-fit: contain;
  }

  @media ${breakpoints.tablet} {
    > div:first-child {
      display: block;
      width: unset;
      margin: auto;
    }

    ${NameWrapper} {
      text-align: center;
      margin-left: 0;
      min-width: unset;
    }
  }

  ${NameWrapper} > div:last-child {
    display: flex;
    flex-direction: column;

    margin-left: 25px;
  }

  @media ${breakpoints.tablet} {
    border-bottom: 0;

    display: flex;
    align-items: center;
    flex: 1;

    margin-top: 0;
    padding-bottom: 0;

    justify-content: center;

    &:first-child {
      border-bottom: 1px solid ${colors.creamLight};
    }

    &:hover {
      ${Hover} {
        display: flex;
        opacity: 1;
      }
    }

    > ${NameWrapper} {
      flex-direction: column;
      align-items: center;
      text-align: center;

      flex: 1;
      height: 100%;
      justify-content: space-around;
    }

    ${NameWrapper} > div:last-child {
      align-items: center;

      margin-left: 0;
    }

    img {
      width: 120px;
      height: auto;

      margin: 60px 0;
    }
  }
`

const Name = styled.p`
  font-family: "Nib Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  /* or 26px */

  /* Cream Light */

  color: #f8f0d8;

  margin: 0;
`

const Button = styled.button`
  ${typography.metaSmall.desktop}

  appearance: none;
  background: 0;
  border: 0;

  color: ${colors.orangeDark};

  text-align: left;
  padding: 0;

  margin: 4px 0;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  background: #020b08;
  opacity: 0.8;

  width: 100vw;
  height: 100vh;

  z-index: 99;

  @media ${breakpoints.tablet} {
    display: none;
  }
`

const Modal = styled.div`
  background: ${props => (props.color ? props.color : colors.sapphireDark)};
  color: ${colors.creamLight};

  position: fixed;
  top: 50%;
  left: 50%;

  z-index: 100;

  transform: translate(-50%, -50%);

  padding: 40px 24px;

  width: 90%;

  min-width: 325px;

  box-sizing: border-box;

  text-align: left;

  ${Name} {
    margin-bottom: 24px;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
`

const IconButton = styled.button`
  appearance: 0;
  background: 0;

  color: ${colors.creamLight};

  position: absolute;
  top: 20px;
  right: 15px;

  border: 0;
  background: 0;
`

export default function Adaptogen({ adaptogen, right = false }) {
  const [modalOpen, setIsModalOpen] = useState(false)

  return (
    <Wrapper>
      <div onClick={() => setIsModalOpen(true)}>
        <img src={adaptogen.illustration.url} alt={adaptogen.name} />
        <NameWrapper>
          <Name>{adaptogen.name}</Name>
          <Button>Learn More</Button>
        </NameWrapper>
      </div>
      <Hover color={adaptogen.color} right={right}>
        <Body>{adaptogen.description.description}</Body>
        <Name>{adaptogen.name}</Name>
      </Hover>
      {modalOpen && (
        <>
          <Overlay onClick={() => setIsModalOpen(false)} />
          <Modal color={adaptogen.color}>
            <IconButton onClick={() => setIsModalOpen(false)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#F8F0D8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#F8F0D8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
            <Name>{adaptogen.name}</Name>
            <Body>{adaptogen.description.description}</Body>
          </Modal>
        </>
      )}
    </Wrapper>
  )
}
