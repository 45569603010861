import React from "react"
import styled from "styled-components"
import { BodyLarge } from "../typography"
import Leaf from "../icons/Leaf"
import colors from "../../styles/colors"

const Wrapper = styled.div`
  position: relative;

  padding: 80px 24px;

  text-align: center;

  max-width: 1440px;
  margin: 0 auto;

  ${BodyLarge} {
    color: ${colors.creamLight};

    max-width: 800px;

    margin: 0 auto 24px;
  }

  &:before,
  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  &:before {
    top: 0;
    bottom: unset;
  }
`
export default function Callout({ body }) {
  return (
    <Wrapper>
      <BodyLarge>{body}</BodyLarge>
      <Leaf />
    </Wrapper>
  )
}
