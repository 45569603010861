import React from "react"
import styled from "styled-components"

import PrimaryButton from "../common/PrimaryButton"
import { BodyLarge, FeaturedHeading } from "../typography"
import Leaf from "../icons/Leaf"
import colors from "../../styles/colors"
import FlowerHeader from "../icons/FlowerHeader"
import DrinkBG from "../../images/drink-bg.png"
import DesktopDrinkBG from "../../images/desktop-drink-bg.png"
import breakpoints from "../../styles/breakpoints"
import { Link } from "gatsby"

const Wrapper: any = styled.div`
  position: relative;

  padding: 140px 24px;

  text-align: center;

  margin: 0 auto;

  background-image: url(${DrinkBG});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  svg {
    margin-bottom: 32px;
  }

  ${FeaturedHeading} {
    display: block;
    color: ${colors.creamLight};

    max-width: 800px;

    margin: 0 auto 32px;
  }

  ${PrimaryButton} {
    background: ${colors.pumpkinDark};

    &:hover {
      background: ${colors.pumpkinLight};
      color: ${colors.firDark};
    }

    color: ${colors.creamLight};
  }

  &:before,
  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  &:before {
    top: 0;
    bottom: unset;
  }

  @media ${breakpoints.tablet} {
    background-image: url(${(props: any) =>
      props.image ? props.image : DesktopDrinkBG});
  }
`
export default function ImageCallout({ image, text }) {
  return (
    <Wrapper image={image}>
      <FlowerHeader />
      <FeaturedHeading>
        {text ?? "Expand your cocktail experience and stay in your moment."}
      </FeaturedHeading>
      <PrimaryButton as={Link} to="/shop">
        Discover Drømme
      </PrimaryButton>
    </Wrapper>
  )
}
