import React from "react"

import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

import LeafBranch from "../icons/LeafBranch"
import { BodyLarge } from "../typography"

const Wrapper = styled.div`
  background: #475443;

  padding: 24px 40px;

  @media ${breakpoints.tablet} {
    padding: 80px 40px;
  }
`

const Box = styled.div`
  background: ${colors.firDark};

  padding: 16px;

  max-width: 990px;

  margin: 0 auto;
`

const InnerBox = styled.div`
  display: flex;

  flex-direction: column;

  color: ${colors.creamLight};

  align-items: center;

  justify-content: center;

  gap: 16px;

  text-align: center;

  padding: 128px 24px;

  border: 1px solid ${colors.creamLight};

  @media ${breakpoints.tablet} {
    padding: 100px 80px;
  }
`

const Name = styled.p`
  font-family: "Founders Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Cream Light */

  color: #f8f0d8;

  margin: 0;
`

export default function Review({ reviewText, reviewer }) {
  return (
    <Wrapper>
      <Box>
        <InnerBox>
          <LeafBranch />
          <BodyLarge>&#8220;{reviewText}&#x201D;</BodyLarge>
          <Name>{reviewer}</Name>
        </InnerBox>
      </Box>
    </Wrapper>
  )
}
