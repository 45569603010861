import { graphql } from "gatsby"
import React from "react"
import Adaptogens from "../components/home/Adaptogens"
import Callout from "../components/home/Callout"
import ImageCallout from "../components/home/ImageCallout"
import Hero from "../components/home/Hero"
import Product from "../components/home/Product"
import Review from "../components/home/Review"
import Layout from "../components/layout"
import loadable from "@loadable/component"
import Seo from "../components/seo"

export const query = graphql`
  query HomeQuery {
    contentfulHomePage {
      heroHeadline
      heroBody {
        heroBody
      }
      heroImage {
        url
      }
      callout {
        callout
      }
      adaptogenImage {
        url
      }
      adaptogenDescription {
        adaptogenDescription
      }
      featuredAdaptogens {
        name
        description {
          description
        }
        illustration {
          url
        }
        color
      }
      reviewerName
      review {
        review
      }
      cocktailExperienceBg {
        url
      }
      cocktailExperienceText
      featuredRecipes {
        slug
        recipeName
        customerFavorite

        alcoholic
        recipeDescription {
          raw
        }
        recipeImage {
          gatsbyImage(
            width: 640
            quality: 100
            height: 480
            placeholder: BLURRED
          )
        }
      }
    }
    allContentfulProduct {
      nodes {
        productTitle
        slug
        productDescription {
          raw
        }
        productImage {
          gatsbyImage(
            width: 560
            height: 640
            quality: 100
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
        primaryTastingNotes {
          name
          illustration {
            url
            gatsbyImage(width: 64, height: 64, placeholder: TRACED_SVG)
          }
        }
        favoriteRecipe {
          slug
          recipeName
        }
      }
    }
  }
`

const RecipeCarousel = loadable(
  () => import("../components/home/RecipeCarousel")
)

export function Head() {
  return <Seo title={"Home"} />
}

export default function Home({ data }) {
  const homepage = data.contentfulHomePage
  const products = data.allContentfulProduct.nodes

  return (
    <Layout>
      <Hero
        headline={homepage.heroHeadline}
        body={homepage.heroBody.heroBody}
        image={homepage.heroImage.url}
      />
      <Callout body={homepage.callout.callout} />
      {products.map(product => (
        <Product product={product} />
      ))}
      <Adaptogens
        image={homepage.adaptogenImage.url}
        adaptogens={homepage.featuredAdaptogens}
        adaptogenDescription={
          homepage.adaptogenDescription.adaptogenDescription
        }
      />
      <RecipeCarousel recipes={homepage.featuredRecipes} />
      <Review
        reviewer={homepage.reviewerName}
        reviewText={homepage.review.review}
      />
      <ImageCallout
        image={homepage.cocktailExperienceBg.url}
        text={homepage.cocktailExperienceText}
      />
    </Layout>
  )
}
