import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { Body, FeaturedLarge, MetaLarge } from "../typography"
import richText from "../../utils/richText"
import PrimaryButton from "../common/PrimaryButton"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { Link } from "gatsby"

const ProductWrapper = styled.div`
  padding: 40px 24px;

  ${FeaturedLarge} {
    display: block;

    &:last-of-type {
      text-align: right;
      margin-bottom: 40px;

      @media ${breakpoints.tablet} {
        margin-bottom: 80px;
      }
    }
  }

  > ${Body} {
    max-width: 400px;
  }

  ${PrimaryButton} {
    margin: 32px 0 40px 0;
    color: ${colors.firDark};
  }

  @media ${breakpoints.tablet} {
    border-left: 1px solid ${colors.creamLight};

    padding: 40px;
    padding-bottom: 0;

    position: relative;

    > div {
      > div:first-child {
        border-left: 1px solid ${colors.creamLight};
      }
    }

    > div:last-child {
      display: flex;
      flex-direction: row-reverse;

      position: relative;

      &:after {
        content: "";

        position: absolute;
        right: -40px;
        top: 0px;

        height: 1px;
        width: 100vw;

        background: ${colors.creamLight};
      }
    }
  }
`

const Wrapper = styled.div`
  color: ${colors.creamLight};

  @media ${breakpoints.tablet} {
    position: relative;
    display: flex;

    max-width: 1440px;
    margin: 0 auto;

    &:nth-child(5) {
      ${ProductWrapper} {
        > div:last-child {
          &:after {
            right: unset;
            left: -40px;
          }

          > div:first-child {
            padding-left: 40px;
          }

          > div:last-child {
            padding-right: 40px;
          }
        }
      }
    }

    &:nth-child(4) {
      flex-direction: row-reverse;

      ${ProductWrapper} {
        border-left: 0;
        border-right: 1px solid ${colors.creamLight};

        > div:last-child {
          flex-direction: row;

          > div:first-child {
            border-left: 0;
            border-right: 1px solid ${colors.creamLight};
          }

          > div:last-child {
            padding-left: 40px;
          }
        }
      }
    }

    > div:first-child {
      flex: 1 1 45%;
    }

    > div:last-child {
      flex: 1 1 55%;
    }

    &:after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }
  }
`

const ImageWrapper = styled.div`
  padding: 40px 24px;

  position: relative;

  border-bottom: 1px solid ${colors.creamLight};

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media ${breakpoints.tablet} {
    height: auto;

    border-bottom: 0;

    padding: 40px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

const RecipeWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 40px;

  flex: 1;

  span,
  p {
    margin: 0;
  }

  > ${MetaLarge} {
    margin-bottom: 8px;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 0;

    padding: 40px 0;

    span {
      display: block;
      margin-bottom: 35px;
    }
  }
`

const TastingNotesWrapper = styled.div`
  padding: 0 16px;

  flex: 1;

  > div {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 0;
  }

  img {
    width: 64px;
    object-fit: contain;
    height: 64px;
    max-width: 100%;
  }

  @media ${breakpoints.tablet} {
    padding: 40px 0;

    ${MetaLarge} {
      display: block;
      margin-bottom: 12px;
    }
  }
`

const TastingNote = styled.div`
  width: 64px;
  height: 96px;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 14px;
  }
`

const FavRecipe = styled.p`
  font-family: "Nib Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  /* or 35px */

  /* Cream Light */

  color: #f8f0d8;

  text-decoration: none;

  transition: color 0.3s linear;

  &:hover {
    color: ${colors.orangeDark};
  }

  @media ${breakpoints.tablet} {
    font-size: 44px;
  }
`

export default function Product({ product }) {
  return (
    <Wrapper>
      <ImageWrapper>
        <GatsbyImage
          image={product.productImage.gatsbyImage}
          alt={product.productTitle}
        />
      </ImageWrapper>
      <ProductWrapper>
        <FeaturedLarge>Discover</FeaturedLarge>
        <FeaturedLarge>{product.productTitle}</FeaturedLarge>
        <Body as="div">{richText(product.productDescription.raw)}</Body>
        <PrimaryButton as={Link} to={`/products/${product.slug}`}>
          Buy Now
        </PrimaryButton>
        <div>
          <RecipeWrapper>
            <MetaLarge as="p">Our favorite cocktail</MetaLarge>
            <FavRecipe as={Link} to={`/recipe/${product.favoriteRecipe.slug}`}>
              {product.favoriteRecipe?.recipeName}
            </FavRecipe>
          </RecipeWrapper>
          <TastingNotesWrapper>
            <MetaLarge>Primary Tasting Notes</MetaLarge>
            <div>
              {product.primaryTastingNotes?.map(tastingNote => (
                <TastingNote>
                  <img src={tastingNote.illustration.url} />
                  <Body>{tastingNote.name}</Body>
                </TastingNote>
              ))}
            </div>
          </TastingNotesWrapper>
        </div>
      </ProductWrapper>
    </Wrapper>
  )
}
