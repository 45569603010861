import React from "react"
import styled from "styled-components"
import Adaptogen from "./Adaptogen"
import { Body, Header2 } from "../typography"
import colors from "../../styles/colors"

import Flower from "../icons/Flower"

import AdaptogenHeader from "../../images/adaptogen-header.png"
import breakpoints from "../../styles/breakpoints"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  color: ${colors.creamLight};

  text-align: center;

  padding: 24px;

  max-width: 1440px;
  margin: 0 auto;

  @media ${breakpoints.tablet} {
    display: grid;

    grid-template-columns: 25% 50% 25%;

    padding: 0;

    position: relative;

    &:after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }
  }
`

const AdaptogensWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  position: relative;

  @media ${breakpoints.tablet} {
    &:after {
      content: "";

      position: absolute;
      right: 0;
      top: 50%;

      transform: translateY(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }

    &:first-child {
      margin-right: 40px;
      border-right: 1px solid ${colors.creamLight};

      &:after {
      }
    }

    &:last-child {
      margin-left: 40px;
      border-left: 1px solid ${colors.creamLight};

      &:after {
        right: unset;
        left: 0;
      }
    }
  }
`
const AdaptogenSection: any = styled.div`
  order: -1;

  background: ${colors.firLight};

  margin: 0 auto;

  ${Header2} {
    height: 160px;

    display: flex;

    align-items: flex-end;
    justify-content: center;

    padding: 20px;

    box-sizing: border-box;

    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${(props: any) => props.image});

    @media ${breakpoints.tablet} {
      background: 0;
    }
  }

  > div {
    margin: 16px;

    border: 1px solid ${colors.creamLight};

    @media ${breakpoints.tablet} {
      background: ${colors.firLight};

      margin: 0 auto;

      max-width: 420px;
    }
  }

  ${Body} {
    margin: 16px;

    display: flex;
    flex-direction: column;

    align-items: center;

    padding: 24px;

    svg {
      margin-top: 16px;
      margin-bottom: 24px;
    }

    @media ${breakpoints.tablet} {
      max-width: 300px;

      margin: 16px auto;
    }
  }

  @media ${breakpoints.tablet} {
    order: unset;

    padding: 40px;

    background-image: url(${(props: any) => props.image});
    background-size: cover;
    background-repeat: no-repeat;

    box-sizing: border-box;

    height: 937px;

    margin: 40px 0;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;
  }
`

export default function Adaptogens({
  image,
  adaptogens,
  adaptogenDescription,
}) {
  return (
    <Wrapper>
      <AdaptogensWrapper>
        <Adaptogen adaptogen={adaptogens[0]} />
        <Adaptogen adaptogen={adaptogens[1]} />
      </AdaptogensWrapper>
      <AdaptogenSection image={image}>
        <Header2>Adaptogens</Header2>
        <div>
          <Body>
            <Flower />
            {adaptogenDescription}
          </Body>
        </div>
      </AdaptogenSection>
      <AdaptogensWrapper>
        <Adaptogen adaptogen={adaptogens[2]} right />
        <Adaptogen adaptogen={adaptogens[3]} right />
      </AdaptogensWrapper>
    </Wrapper>
  )
}
